import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthConfig, OAuthErrorEvent, OAuthEvent, OAuthInfoEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';

const API_URL: string = environment.onetalent_apiurl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);
  private loginError = new BehaviorSubject<boolean | null>(null);

  authConfig: AuthConfig = {
    issuer: 'https://accounts.google.com',
    redirectUri: window.location.origin + '/callback',
    clientId: environment.google_client_id,
    scope: 'openid profile email https://www.googleapis.com/auth/drive',
    strictDiscoveryDocumentValidation: false,
    useSilentRefresh: true,
    showDebugInformation: true,
  };

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private oauthService: OAuthService
  ) {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setStorage(localStorage);

    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthSuccessEvent) {
        if (event.type === "token_received") {
          this.updateLoginStatus()
        }
      }
      if (event instanceof OAuthErrorEvent) {
        this.handleLoginError(true);
      }
    })
  }

  public initLogin(): void {
    this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
      this.updateLoginStatus();
      if (this.oauthService.hasValidAccessToken()) {
        this.setupAutoRefreshToken();
        this.getUserRights(this.getUserEmail());
      } else {
        this.initLoginFlow()
      }
    }).catch((error) => {
      console.log(error)
      this.handleLoginError(true);
      localStorage.clear();
      sessionStorage.clear();
    });
  }

  private handleLoginError(error: boolean): void {
    this.loginError.next(error);
    this.ngZone.run(() => this.router.navigate(['/login']));
  }

  public get loginError$(): Observable<boolean | null> {
    return this.loginError.asObservable();
  }

  initLoginFlow(): void {
    this.oauthService.initCodeFlow();
  }

  private setupAutoRefreshToken(): void {
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public login(): void {
    this.oauthService.initLoginFlow();
  }

  public logout(): void {
    this.oauthService.logOut();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  getUserEmail(): any {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['email'];
  }

  getUserName(): any {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['name'];
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  public hasValidIdToken() {
    return this.oauthService.hasValidIdToken();
  }

  private updateLoginStatus(): void {
    const isUserLoggedIn = this.hasValidIdToken();
    this.loggedIn.next(isUserLoggedIn);
  }

  async getUserRights(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const accessToken = this.oauthService.getIdToken();

      const headers: HttpHeaders = new HttpHeaders({ 'Authorization': `${accessToken}` });

      this.httpClient.get(API_URL + 'getConsultantsAccessByEmail/' + email, { headers })
        .subscribe(
          (response: any) => {
            localStorage.setItem("userRights", JSON.stringify(response));
            resolve();
          },
          (error: any) => {
            console.error('Error fetching user rights:', error);
            console.error('Error details:', error.error);
            reject(error);
          }
        );
    });
  }
}
