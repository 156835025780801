import { AuthService } from 'src/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public currentpage: string = ''
  public init: boolean = false;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((status) => {
      if (status)
        this.init = true;
      else
        this.init = false;
    })
  }

  public logout() {
    this.authService.logout();
  }
}
