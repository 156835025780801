<div *ngIf="downloadOption" class="w-full my-2 flex justify-center items-center space-x-2">
  <div class="w-44 relative ">
    <button id="modeRechercheDropdown" (mouseenter)="showDropdown = true;" (mouseleave)="typeSelectLeave()"
      class="w-full text-gray-500 border border-gray-200 bg-white-700 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 inline-flex  items-center"
      type="button">
      {{selectedType}}
      <svg class="w-4 h-4 absolute right-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
    <div [hidden]="!showDropdown && !dropdownMenuHovered" (mouseenter)="dropdownMenuHovered = true;"
      (mouseleave)="dropdownMenuHovered = false;"
      class="z-10 w-full absolute  bg-white divide-y divide-gray-100 rounded-lg shadow  ">
      <ul class="text-left text-sm text-gray-700 " aria-labelledby="modeRechercheDropdown">
        <li>
          <button (click)="changeType('Format PDF')"
            class="block w-full text-left p-3 text-gray-900 hover:text-red-400  hover:border hover:bg-gray-200">
            Format PDF</button>
        </li>
        <li>
          <button (click)="changeType('Format PPTX')"
            class="block w-full text-left p-3 text-gray-900 hover:text-red-400  hover:border hover:bg-gray-200">
            Format PPTX
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="relative w-auto">
    <button type="button" [disabled]="selectedType =='Choisir le format'" (click)="downloadFile()"
      class="focus:outline-none text-white  w-full {{downloadBtnSpecialClass}}  font-medium rounded-lg  text-sm px-4 py-3 inline-flex  text-center ">
      Télécharger
    </button>
  </div>
</div>

<iframe [src]="url" style="width:100%;height:93vh;"></iframe>