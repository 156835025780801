import { AuthService } from 'src/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as saveAs from 'file-saver';
import { Observable, fromEvent, map, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveService {

  public accessToken: string;

  private readonly apiUrl = 'https://www.googleapis.com/drive/v3';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  /**
   * Get google drive's access token
   * @param void
   * @returns void
   */
  getAccessToken(): void {
    this.accessToken = this.authService.getAccessToken();
  }

  /**
   * Download either pdf file or pptx from google drive
   * @param type
   * @param fileId
   * @returns void
   */
  downloadFile(type: string, fileId: string) {
    if (!this.accessToken)
      this.getAccessToken();
    const metadataUrl = `${this.apiUrl}/files/${fileId}?supportsAllDrives=true`;
    const downloadUrlPdf = `${this.apiUrl}/files/${fileId}?alt=media`;
    const downloadUrlPptx = `${this.apiUrl}/files/${fileId}?alt=media&mimeType=application/vnd.openxmlformats-officedocument.presentationml.presentation?supportsAllDrives=true`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`);

    // Get the file's metadata
    this.httpClient.get(metadataUrl, { headers }).subscribe((metadata: any) => {
      const filename = metadata.name;
      // Downloading a pdf
      if (type === "PDF") {
        if (filename.endsWith('.pdf')) {
          // File is a PDF
          this.httpClient.get(downloadUrlPdf, { headers, responseType: 'blob' }).subscribe({
            next: (data) => {
              const pdfBlob = new Blob([data], { type: 'application/pdf' });
              saveAs(pdfBlob, filename);
            },
            error: (err) => {
              console.log(err)
            }
          })
        } else if (filename.endsWith('.pptx')) {
          //file is pptx
          // const url = `https://docs.google.com/presentation/d/${fileId}/export/pdf?id=${fileId}&pageid=p1`;
          const url = `https://docs.google.com/presentation/d/${fileId}/export/pdf`;
          this.httpClient.get(url, { headers, responseType: 'blob' }).subscribe({
            next: (data) => {
              const pdfBlob = new Blob([data], { type: 'application/pdf' });
              saveAs(pdfBlob, filename.replace('pptx', 'pdf'));
            },
            error: (err) => {
              console.log(err)
            }
          })
        };
        // Downloading a pptx
      } else {
        if (type === "PPTX") {
          this.httpClient.get(downloadUrlPptx, { headers, responseType: 'blob' }).subscribe({
            next: (data) => {
              saveAs(data, filename);
            },
            error: (err) => {
            }
          })
        }
      }
    });
  }

  /**
   * Download a picture data from google drive
   * @param link
   * @returns Observable<string>
   */
  downloadPicture(link: string): Observable<string> {
    if (!this.accessToken)
      this.getAccessToken();
    const fileId = this.extractFileIdFromUrl(link);
    const url = `${this.apiUrl}/files/${fileId}?alt=media`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`);
    return this.httpClient.get(url, { headers, responseType: 'blob' }).pipe(
      switchMap(response => {
        const reader = new FileReader();
        const onload$ = fromEvent(reader, 'load');
        reader.readAsDataURL(response);
        return onload$.pipe(
          map(() => reader.result as string),
          take(1)
        );
      })
    );
  }

  /**
   * Extract the file ID from a google drive share link
   * @param url
   * @returns string | null
   */
  extractFileIdFromUrl(url: string): string | null {
    const regex: RegExp = /\/d\/(.+?)\//;
    const match: RegExpMatchArray | null = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      console.error('Invalid Google Drive URL');
      return null;
    }
  }
}



