import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { AuthService } from 'src/services/auth.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  public isLoggedIn: boolean = false;
  public isLoginPage: boolean = false;
  private loginSubscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private title: Title,
    private router: Router,
  ) {
    this.title.setTitle('One Talent');

  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!this.isLoggedIn && event.url.split('#')[0] !== '/callback') {
          sessionStorage.setItem('redirect', event.url)
        }
      }

      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === '/login';
      }
    });

    this.authService.initLogin();

    this.loginSubscription = this.authService.isLoggedIn.subscribe(status => {
      this.isLoggedIn = status;
    });
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }
}
