<div class="bg-primary-blue sticky top-0 z-50">
  <mat-toolbar class="text-sm font-medium !m-auto max-w-screen-xl px-4">
    <button mat-icon-button routerLink="/" class="mr-11">
      <img src="../../../assets/common/nav-logo.png">
    </button>
    <button mat-icon-button routerLink="/" [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'bg-white/20 text-white'" class="text-light-grey py-2 px-3 rounded-md mr-4">
      Recherche
    </button>
    <button routerLink="/profil" mat-icon-button [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'bg-white/20 text-white'" class="text-light-grey py-2 px-3 rounded-md mr-4">
      Mon profil
    </button>
    <button routerLink="/organigramme" mat-icon-button [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'bg-white/20 text-white'" class="text-light-grey py-2 px-3 rounded-md">
      Organigramme
    </button>
    <span class="example-spacer"></span>
    <button (click)="logout()" mat-icon-button class="text-white flex items-center py-2 px-3 rounded-md">
      <mat-icon class="mr-3">
        logout
      </mat-icon>
      <span>Déconnexion</span>
    </button>
  </mat-toolbar>
</div>