import { EventsService } from './../services/events.service';
import { NavService } from 'src/services/nav.service';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode, LOCALE_ID } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentModule } from './common/common-component.module';
import { LoginComponent } from './login/login.component';

import { AuthService } from 'src/services/auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import * as fr from '@angular/common/locales/fr';

import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonComponentModule,
    MatSelectModule,
    HttpClientModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    AuthService,
    Title,
    NavService,
    EventsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(fr.default);
  }
}
