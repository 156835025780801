import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleDriveService } from 'src/services/google-drive.service';
@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css']
})
export class FileViewerComponent {

  public url: any;
  public downloadOption : Boolean = false;
  downloadUrl : any;
  selectedType ='Choisir le format';
  showDropdown : boolean = false;
  dropdownMenuHovered : boolean = false;
  downloadBtnSpecialClass : string = 'bg-gray-400  cursor-not-allowed';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private googleDriveService: GoogleDriveService,
  ){
    this.downloadOption = data.downloadOption ? data.downloadOption : false ;

    if(data.mode ==='preview'){
      const tempUrl = data.url.slice( 0, data.url.indexOf('edit') )+'preview';
      this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(tempUrl);
      this.downloadUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data.url);
    }
    else{
      this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(data.url);
      this.downloadUrl = this.url;
    }
  }

  typeSelectLeave(){
    setTimeout(()=>{this.showDropdown = false;}, 500)
  }

  changeType(type: string){
    this.selectedType = type;
    this.downloadBtnSpecialClass = 'bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300';
  }

  downloadFile(){
    if(this.downloadUrl){
      const fileId = this.googleDriveService.extractFileIdFromUrl(JSON.stringify(this.url) );
      if(fileId){
        this.googleDriveService.downloadFile(this.selectedType.split(/(\s+)/)[2], fileId!);
      }
      else
        console.log('Invalid Google Drive URL');
    }else{
      console.log('download error');
    }
  }
  

}
