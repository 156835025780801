<div mat-dialog-title class="!flex !items-center !py-2 !px-6">
  <mat-icon class="mr-2 material-icons-outlined pink-icon">warning</mat-icon>
  <label class="text-gray-700 font-bold">Attention</label>
</div>
<mat-dialog-content>{{ data }}</mat-dialog-content><br>
<mat-dialog-actions>
  <div class="absolute bottom-0 right-0 p-2">
    <button class="grey-button text-primary-blue rounded-md p-2 m-2 text-center inline-flex items-center font-medium text-sm"
            [mat-dialog-close]="false"><span>Annuler</span></button>
    <button class="bg-secondary-pink text-white rounded-md p-2 text-center inline-flex items-center font-medium text-sm disabled:bg-opacity-50" 
            [mat-dialog-close]="true"><span>Supprimer</span></button>
  </div>
</mat-dialog-actions>