<div class="flex h-full">
  <div class="w-2/5 flex flex-col justify-evenly">

    <div class="px-24 flex flex-col justify-center items-center">
      <img class="mb-32	" src="../../assets/common/login-logo.png">

      <button (click)="signInWithGoogle()">Sign In</button>
    </div>

    <div *ngIf="connexionFailed" class="flex items-center mx-10 p-4 bg-secondary-pink bg-opacity-5">
      <mat-icon class="mr-3 text-secondary-pink">
        cancel
      </mat-icon>
      <p class="font-medium text-sm text-secondary-pink">Veuillez vous connecter pour accéder à la plateforme</p>
    </div>
  </div>
  <div class="w-full flex items-center justify-center bg-primary-blue">
    <img src="../../assets/common/login-bg.png" alt="Login background">
  </div>
</div>