import { AuthService } from 'src/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public connexionFailed: boolean | null = false;

  constructor(
    public authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.authService.loginError$.subscribe(error => {
      if (error)
        this.connexionFailed = true;
    });
  }

  signInWithGoogle(): void {
    this.authService.login();
  }
}
