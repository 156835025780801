import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  standalone: true,
  imports: [],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.css'
})
export class CallbackComponent implements OnInit {

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    let url = sessionStorage.getItem('redirect');
    if (url === '/login')
      url = ''
    this.router.navigate([url || '']);
    sessionStorage.clear();
  }

}
