import { HeaderComponent } from './header/header.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import {FileViewerComponent } from './file-viewer/file-viewer.component';
import {ConfirmationDialogComponent} from './conf-dialog/conf-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    HeaderComponent,
    FileViewerComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule
  ],
  exports: [
    HeaderComponent,
    FileViewerComponent,
    ConfirmationDialogComponent
  ]
})
export class CommonComponentModule { }
