import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private params: Map<string, string> = new Map<string, string>();

  constructor() { }


  /*
   *Set Params in Key
  */
  setActiveParams(key: any, params: any){
    this.params.set(key, params);
  }

  /*
   *Get Params in Key
  */
  getActiveParams(key: any): any{
    if(this.hasActiveParams(key)){
      return JSON.parse(this.params.get(key)!);
    }
    return null;
  }

  /*
   *Check if there is a params in key
  */
  hasActiveParams(key: any) {
    return this.params.has(key);
  }

  /*
   *Clear params in key
  */
  clearActiveParams(key: any) {
    this.params.delete(key);
  }

  /*
   *Clear all
  */
  clear() {
    this.params.clear();
  }
}
