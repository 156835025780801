import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const logged = this.authService.hasValidIdToken();
    if (logged) {
      return true
    } else {
      try {
        this.authService.initLoginFlow();
      } catch (error) {
        console.log(error);
        this.router.navigate(['/login']);
        return false;
      }
      return false;
    }
  }
}
